import React, { useEffect, useMemo, useState } from "react";
import { HeadFC, graphql, useStaticQuery } from "gatsby";
import { Container, Grid, Typography, Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowRight } from "@mui/icons-material";
import LayoutType from "../constants/layout-type";
import Colors from "../constants/colors";
import MotionBox from "../components/motion/motionBox";

const AppSetup = () => {
    const [loading, setLoading] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: !loading ? 1 : 0, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: !loading ? 'visible' : 'hidden', background: Colors.BACKGROUND_DARK }
    }), [loading]);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            <MotionBox
                {...firstBoxAnimationProps}
            >
                <Container maxWidth="md">
                    <Box textAlign="center" pt={{ xs: 8, sm: 10, md: 13 }} pb={{ xs: 2, sm: 3, md: 4 }} mb={{ xs: 1, sm: 2, md: 3 }} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                        <Typography component="h1" variant="h1" sx={{ mt: { xs: 2, sm: 3, md: 4 }, mb: 0, maxWidth: "100%", fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
                            Screen Keep Setup Instructions
                        </Typography>
                        <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2, md: 4 }, mt: { xs: -2, sm: -1, md: 0 }, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}>
                            Follow these steps to get started with Screen Keep
                        </Typography>
                    </Box>
                </Container>
            </MotionBox>
            <Container maxWidth="lg">
                <Grid container spacing={4} sx={{ mt: 4, mb: 4 }}>

                    {Array.from({ length: 10 }).map((_, index) => (<React.Fragment key={`step-${index}`}>
                        {index === 9 && (
                            <Grid item xs={12} sm={6} md={4} sx={{ display: { xs: 'none', md: 'flex' } }}> {/* Empty for alignment */}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4} key={`step-${index + 1}`} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box textAlign="center" pt={2} pb={2} flexDirection="column" display="flex" alignItems="center" alignContent="center">
                                <Typography component="h3" variant="h5" sx={{ mt: 0, mb: 2, maxWidth: "100%" }}>
                                    Step {index + 1}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 2, minHeight: '80px' }}> {/* Increased minHeight for better alignment */}
                                    {index === 0 && "Begin your Screen Keep journey: Download our app directly from the Google Play Store."}
                                    {index === 1 && "Find and launch the app by tapping its icon on your device's home screen."}
                                    {index === 2 && "Easily navigate through the app: Use your remote's back button, pressing it twice to open the main menu."}
                                    {index === 3 && "Set up your device: Go to the settings screen and select 'Register Device'."}
                                    {index === 4 && "Join the Screen Keep community by creating a new account or signing in on our registration page."}
                                    {index === 5 && "Link your device: In your dashboard, select the 'Register Device' option for straightforward setup."}
                                    {index === 6 && "Personalize your device: Assign it a unique name, add an optional description, and key in the code displayed on your TV."}
                                    {index === 7 && "Save your configurations: Verify your device's details and then click 'Save' to complete the registration process."}
                                    {index === 8 && "Configure your device settings online by pressing 'Manage' next to your newly added device."}
                                    {index === 9 && "Congratulations! Dive into the Screen Keep experience and enjoy seamless device management."}
                                </Typography>
                                {index === 0 && (
                                    <Button
                                        variant="contained"
                                        endIcon={<ArrowRight />}
                                        href="https://play.google.com/store/apps/details?id=com.screenkeep.screenkeep"
                                        target="_blank"
                                        sx={{ mt: 2 }}
                                    >
                                        Download App
                                    </Button>
                                )}
                                {index === 4 && (
                                    <Button
                                        variant="contained"
                                        endIcon={<ArrowRight />}
                                        href="/auth/register/"
                                        target="_blank"
                                        sx={{ mt: 2 }}
                                    >
                                        Create an Account
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </React.Fragment>))}

                    {/* Ensure the last item is centered if alone in the last row */}
                </Grid>
            </Container>

        </>
    );
};


AppSetup.layoutType = LayoutType.DefaultFullWidth;

export default AppSetup;

export const Head: HeadFC = () => (
    <>
        <title>ScreenKeep - Setup Instructions</title>
        <meta name="description" content="Subscription free digital signage solution for Android TV. Setup instructions for configuring Screen Keep and displaying your first web page in the app. Transform any Android TV into a digital signage solution with ease, and display web content seamlessly." />
        <meta name="keywords" content="Screen Keep,ScreenKeep,Digital Signage Devices,Cheap Digital Signage,Free Digital Signage,Web Page Digital Signage,Show Web Page On TV,Digital Signage,Android TV Digital Signage,Web Content,Screen Keep App" />
    </>
)